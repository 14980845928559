@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  width: 100%;
  height: 100%;
}
body {
  overflow: hidden; /* Prevent pull-to-refresh: https://stackoverflow.com/a/73941939/2927114 */
  margin: 0;
  font-family: "DIN Alternate", "Franklin Gothic Book", "DIN Condensed", "DINCondensed-Bold", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #0f172a;
  user-select: none;
  position: fixed;
  background-color: #000f2a; /* also in manifest.json */
}

#root {
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  min-width: 320px;
  /* iPhone 15 Pro dimensions */
  max-width: 430px;
}

/* NHaasGroteskDSStd-75Bd is for display (e.g. h1). Tighter than the NHaasGroteskTXPro-75Bd */
@font-face {
  font-family: "NHaasGroteskDSStd-75Bd";
  src: url("./fonts/NHaasGroteskDSStd-75Bd.woff") format("woff");
  font-weight: 700;
  font-style: bold;
  font-display: block;
}
@font-face {
  font-family: "NHaasGroteskTXPro-75Bd";
  src: url("./fonts/NHaasGroteskTXPro-75Bd.woff") format("woff");
  font-weight: 700;
  font-style: bold;
  font-display: block;
}
@font-face {
  font-family: "NHaasGroteskTXPro-65Md";
  src: url("./fonts/NHaasGroteskTXPro-65Md.woff") format("woff");
  font-weight: 600;
  font-style: medium;
  font-display: block;
}
@font-face {
  font-family: "NHaasGroteskTXPro-55Rg";
  src: url("./fonts/NHaasGroteskTXPro-55Rg.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* Typography */
h1, h2, h3, h4 {
  text-transform: none;
}
h1 {
  font-family: "NHaasGroteskDSStd-75Bd", sans-serif;
  font-size: 3rem;
  line-height: 1;
}
h2 {
  font-family: "NHaasGroteskDSStd-75Bd", sans-serif;
  font-size: 1.5rem;
  line-height: 1.2;
}
h3 {
  font-family: "NHaasGroteskTXPro-65Md", sans-serif;
  font-size: 1.25rem;
  line-height: 1.2;
}
h4 {
  font-family: "NHaasGroteskTXPro-65Md", sans-serif;
  font-size: 1rem;
  line-height: 1.2;
}
p, ul, li {
  font-family: "NHaasGroteskTXPro-55Rg", sans-serif;
  text-transform: none;
  line-height: 1.6;
}

.hr {
  border-style: solid;
  /* border-image: linear-gradient(to right, #fdfefb, #fb2b32, #fb2b32, #fb2b32, #fdfefb) 1; */
  /* border-image: linear-gradient(135deg, rgb(0 12 41) 0%, rgb(0 146 183) 35%, rgb(0 232 241) 100%) 1; */
  border-image: linear-gradient(to right, #ff100d, #ff250f, #fd331f, #f22041, #e4076e) 1;
  border-bottom-width: 1px;
}

.din {
  font-family: "DIN Alternate", "Franklin Gothic Book", "DIN Condensed", "DINCondensed-Bold", sans-serif;
}

.haas-75 { font-family: "NHaasGroteskDSStd-75Bd", sans-serif; }
.haas-65 { font-family: "NHaasGroteskTXPro-65Md", sans-serif; }
.haas-55 { font-family: "NHaasGroteskTXPro-55Rg", sans-serif; }

.better-list li {
  display: block;
  padding-left: 1rem;
  position: relative;
}
.better-list b {
  left: 0;
  position: absolute;
}

/* buttons */
button,
button:focus,
input,
input:focus {
  outline: none;
 }

.btn {
  white-space: nowrap;
  display: flex;
  align-items: center;
  outline: none;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.1em;
  border-radius: 9999px;
  justify-content: center;
  background-image: linear-gradient(135deg, rgba(255,0,0,1) 0%, rgba(255,64,0,1) 100%);
  color: #fdfefb;
  padding: 0.75rem 1.5rem 0.625rem; /* vertically center NHaasGroteskDSStd-75Bd */
  text-transform: uppercase;
  /* font-family: "DIN Alternate", "Franklin Gothic Book", "DIN Condensed", "DINCondensed-Bold", sans-serif; */
  font-family: "NHaasGroteskDSStd-75Bd";
  font-size: 0.875rem;
}

.btn-secondary {
  border: 0.1875rem solid #fb2b32;
  color: #fb2b32;
  background-color: white;
  background-image: none;
}

/* Splash screen */
#splashscreen {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.logo-bg {
  height: 60%;
  transition-property: height;
  transition-duration: 1000ms;
  transition-delay: 250ms;
}

.logo-loaded {
  height: 0;
}

/* Modals */
.modal-bg {
  background-color: rgb(0 0 0 / 0.7);
}

/* Homepage */
.lede {
  font-family: "NHaasGroteskDSStd-75Bd";
  font-size: 2.375rem;
  font-size: 2.125rem;
  font-size: 1.75rem;
  line-height: 2.5rem;
  letter-spacing: 0.0125em;
  /* background: linear-gradient(135deg, rgb(0 12 41) 0%, rgb(0 146 183) 35%, rgb(0 232 241) 100%); */
  background: linear-gradient(135deg, rgb(255 19 14) 0%, rgb(255 53 17) 35%, rgb(230 11 103) 90%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: block;
}

.feature-list-item {
  background-color: #e9f8fb;
  padding: 1.5rem;
  border-radius: 0.375rem;
}

.feature-list-item h2 {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.feature-list-item h2 span {
  flex-grow: 1;
}

/* How-tos */
.howto {
  position: relative;
}

.howto-image {
  margin-top: 0.5rem;
  border-radius: 0.24rem;
}

.howto-touch {
  position: absolute;
}

/* Header */
#header {
  z-index: 10;
  font-size: 15px;
  height: 3.5rem;
  color: white;
  position: fixed;
  left: auto;
  top: 0;
  width: 100%;
  min-width: 320px;
  max-width: 430px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.header-button {
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  color: #fdfefb;
}

.header-button.has-sub-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.header-button svg {
  filter: drop-shadow(0 1px 1px rgba(0,0,0,0.25));
  width: 1.5rem;
  height: 1.5rem;
}

.header-button.is-active svg {
  filter: none;
}

.header-button.is-active {
  background-color: #fdfefb;
  color: #ff0f0d;
  filter: drop-shadow(0 1px 1px rgba(0,0,0,0.25));
}

.header-button.is-active.has-sub-right {
  filter: none;
}

.header-button-sub {
  transform-origin: left;
  display: flex;
  box-shadow: inset 0 0 0 1px rgba(253, 254, 251, 0.5);
  background-color: #ff0f0d;
  margin-left: -1px;
  /* gap: 0.0625rem;
  padding-left: 0.125rem;
  padding-right: 0.125rem; */
  gap: 0.125rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.header-sub-is-open {
  filter: drop-shadow(0 1px 1px rgba(0,0,0,0.25));
}

.header-button-sub svg {
  width: 1.375rem;
  height: 1.375rem;
}

.sub-header-divider {
  border-left: 1px solid #fe8a89;
}

.link-button {
  color: #009cfc;
}


/* Footer */
.footer {
  margin-top: auto; /* align bottom */
  background-color: #0f172a;
  color: #94a3b8;
}

.footer-buttons {
  display: flex;
  width: 100%;
  align-content: space-between;
}

.footer .footer-btn {
  display: flex;
  align-items: center;
  color: #c5eaf5;
}

/* List Items */
#list-wrapper {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
/* Scrolling a flexbox with overflowing content: https://stackoverflow.com/a/21541021/2927114 */
.item-list {
  display: flex;
  min-height: min-content;
}

.item {
  text-transform: uppercase;
  border-style: solid;
  border-image: linear-gradient(to right, #fff, #c5eaf5, #c5eaf5, #c5eaf5, #fff) 1; /* #e9f8fb */
  border-bottom-width: 1px;
  background-color: #fdfefb;
}

.item:first-of-type {
  box-shadow: 0 0 3px 0 rgba(0,0,0,0.1);
}
.item:last-of-type {
  border: none;
}

.text-input-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fdfefb;
}

/* Alert */
.alert {
  font-family: "NHaasGroteskTXPro-65Md", sans-serif;
}

.alert-sorted-stars {
  position: absolute;
  color: #00e8f1;
  width: 2rem;
  height: 2rem;
  opacity: 1;
  transition: all 300ms ease-in;
}

.alert.visible .alert-sorted-stars {
  opacity: 0;
  width: 16rem;
  height: 16rem;
}

.alert-sorted-text {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.025rem;
  transition: opacity 900ms cubic-bezier(1,0,1,0.25);
  opacity: 1;
}

.alert.visible .alert-sorted-text {
  opacity: 0;
}


/* Tooltip */
.tooltip {
  position: absolute;
  transform: translateX(-50%);
  display: table;
  background-color: #039cff;
  color: white;
  border-radius: 0.375rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.025em;
  font-family: "NHaasGroteskTXPro-55Rg", sans-serif;
  text-align: center;
}

.tooltip.hide {
  opacity: 50%;
}

/* .tooltip-above { bottom: 2.5rem; } */
.tooltip-below { top: 2.5rem; }

.tooltip::after {
  position: absolute;
  left: calc(50% - 8px);
  content: "";
  display: block;
  width: 16px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.tooltip-above.tooltip::after {
  bottom: -6px;
  border-top: 6px solid #039cff;
}

.tooltip-below.tooltip::after {
  top: -6px;
  border-bottom: 6px solid #039cff;
}

/* Cost input */
.input-cost {
  position: relative;
  background-color: #dff0f4;
  height: 3rem;
  width: 100%;
  border-radius: 0.375rem;
  overflow: hidden;
}
.input-cost input {
  position: absolute;
  top: 0;
  left: 3rem;
  bottom: 0;
  right: 0;
  width: calc(100% - 5.875rem);
  text-align: right;
}
.input-cost::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "$";
  display: block;
  height: 100%;
  background: #039cff;
  color: white;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "NHaasGroteskTXPro-65Md", sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  box-shadow: 1px 0 1px rgba(0,0,0,0.2);
}

.input-cost::after {
  position: absolute;
  top: 0;
  right: 0.75rem;
  content: ".00";
  display: block;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #94a3b8;
  font-size: 1.125rem;
  letter-spacing: 1.8px;
  font-family: "NHaasGroteskTXPro-65Md", sans-serif;
}

/* Modals */
.modal {
  border-radius: 1rem;
  text-align: left;
  padding: 1.75rem 1.5rem 1.5rem;
  box-shadow: 0 0 30px 0 rgba(0,0,0,0.5);
}

.modal-heading {
  text-align: center;
  line-height: 1.2;
  margin-bottom: 1.25rem;
  /* color: #0092b7; */
  font-size: 1.625rem;
  background: linear-gradient(135deg, rgb(0 107 140) 0%, rgb(0 146 183) 35%, rgb(0 232 241) 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: block;
}

/* Button Group (Charts page) */
.btn-group-wrapper {
  display: flex;
  width: 100%;
}

.btn-group {
  flex-grow: 1;
  width: 25%;
  padding: 0.375rem 0;
  font-size: 0.875rem;
  border-right: 1px solid white;
  font-family: "NHaasGroteskTXPro-65Md", sans-serif;
  color: #0f172a;
  background-color: #c1e8ff;
}

.btn-group.is-current {
  background-color: #039cff;
  color: white;
}

.btn-group:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.btn-group:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-right: none;
}

/* checkbox */
.checkbox {
  width: 1.25rem;
  height: 1.25rem;
}
.checkbox-is-checked {
  color: #039cff;
}

/* icon buttons */
.icon-btn-group {
  font-family: "NHaasGroteskTXPro-55Rg", sans-serif;
}
.icon-btn {
  align-items: center;
  color: #039cff;
}

.icon-btn-caution {
  color: #fb2b32;
}

.icon-btn-disabled {
  color: rgb(148 163 184);
}


/* Range slider */

/* Slider container */
#rangeSlider {
  -webkit-appearance: none;
  appearance: none;
  height: 12px; /* Slider height */
  background: #e5e5e5; /* Slider background */
  border: 1px solid #ccc;
  outline: none; /* Remove outline */
  opacity: 0.7; /* Partial transparency */
  transition: opacity .2s; /* Smooth transition for mouse-over effects */
  border-radius: 99rem
}

#rangeSlider:hover {
  opacity: 1; /* Fully opaque on hover */
}

/* Slider thumb */
#rangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 25px; /* Thumb width */
  height: 25px; /* Thumb height */
  background: #039cff; /* Thumb color */
  cursor: pointer; /* Cursor changes to pointer when over the thumb */
  border-radius: 50%; /* Round shape */
}

#rangeSlider::-moz-range-thumb {
  width: 25px; /* Thumb width */
  height: 25px; /* Thumb height */
  background: #039cff; /* Thumb color */
  cursor: pointer; /* Cursor changes to pointer when over the thumb */
  border-radius: 50%; /* Round shape */
}

/* Focus styles for the thumb */
#rangeSlider:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 5px rgba(0, 122, 188, 0.2); /* Focus ring style */
}

#rangeSlider:focus::-moz-range-thumb {
  box-shadow: 0 0 0 5px rgba(0, 122, 188, 0.2); /* Focus ring style */
}

/* scroll bottom of list element into view */
/* #list-bottom {
  height: 2px;
  background: hotpink;
} */
